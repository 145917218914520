/* fonts */
@font-face {
    font-family: 'playfair-display';
    src: url('../../../../public/media/Fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf');
}
@font-face {
    font-family: 'source-sans-pro-regular';
    src: url('../../../../public/media/Fonts/source-sans-pro/SourceSansPro-Regular.ttf');
}
@font-face {
    font-family: 'source-sans-pro-bold';
    src: url('../../../../public/media/Fonts/source-sans-pro/SourceSansPro-Bold.ttf');
}
/* Custom datepicker */
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
    border-radius: 8px;
    font-family: 'source-sans-pro-bold';
}

.text-align-center {
    text-align: center;
}
.justify-content-center {
    display: flex;
    justify-content: center;
}

.textInput {
    height: 123w;
    border: 1px solid red
}

div[id="image_selector"]:hover {
    cursor: pointer;
}
u[id='terms-of-use-tag']:hover{
    cursor: pointer;
}
div[id="close-box"]:hover{
    cursor: pointer;
}
input[id="input_hidden"]{
    display: none;
}
input[id='search-input-1']:focus {
    outline: none;
}
input[id='search-input']:focus {
    outline: none;
}
input[id="date-picker-input"] {
    font-family: 'source-sans-pro-regular' !important ;
    font-weight: 400 !important;
    color: #503F40;
    /* padding-right: 12px; */
}

::placeholder{
    font-family: 'source-sans-pro-regular' !important ;
    font-weight: 400 !important;
    font-size: 16 !important;
    padding-left: 12 !important;
    color: #2D191E61;
 }

.hidden {
    display: none;
}

.imageSelector {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #CDC4C2;
}

.placeholder {
    font-weight: '400';
}

.custom-date-picker {
    background-color: #FFFFFF;
    border: 0px;
    height: 48px;
}

.custom-button {
    /* background-color: #503F40; */
    border-radius: 4px;
    height: 48px;
    outline-width: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-button:hover {
    cursor: pointer;
}

.buttonText {
    color: white;
    font-size: 16px;
    font-family: 'source-sans-pro-bold';
    font-weight: 700;
}

.header-address-text {
    color: #766667;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    font-family: 'playfair-display';
}

.header-name-text {
    color: #766667;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
}

.custom-card-header {
    display: flex;
    flex-direction: row;
    /* margin-inline: 6px; */
}

.title-header {
    display: flex;
    flex-direction: row;
    /* margin-inline: 26px; */
}

.title-header-text {
    color: #766667;
    display: flex;
    /* align-items: center; */
    font-size: 16;
    font-weight: 600;
    color: #766667;
}

.loader {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;
    border: 0.5em solid rgba(0, 0, 0, 0.2);
    border-left: 0.5em solid #000000;
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
    transition: opacity 0.3s;
}

.loader--hide {
    opacity: 0;
  }

  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.loader-text {
    position: absolute;
    top: calc(62% - 4em);
    left: calc(46% - 4em);
    color: #503F40;
}

.loader-background-color {
    background-color: #FFFAF5 !important;
}

.memory-wrapper {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    /* padding: 20px; */
    padding-bottom: 12px;
}

.memory-card-text-name {
    font-weight: 600;
    font-size: 16;
    line-height: 20.11px;
    color: #766667;
    font-family: 'source-sans-pro-bold';
}

.memory-card-text-date {
    font-weight: 400;
    font-size: 14;
    line-height: 17.6px;
    color: #A09291;
    font-family: 'source-sans-pro-regular';
}

.memory-detail-text {
    font-weight: 400;
    font-style: italic;
    font-size: 16;
    line-height: 20.11px;
    color: #766667;
}

.bw-1 {
    border: 1px solid red
}

.image-fit {
    object-fit: contain;
}

.top-bar {
    background-color: #FFFAF5;
    border-bottom: 1px solid #2F1A1F3D;
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
}

.contact-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20.11px;
    color: #503F40 !important;
    font-family: sans-serif;
}
.scroll {
    overflow-y: scroll;
}
.tag {
    /* overflow-y: scroll; */
}

.tag-header-text{
    font-weight: 600;
    font-size: 14px;
    line-height: 18.66px;
    color: #766667;
    font-family: 'playfair-display';
}

.tag-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20.11px;
    color: #766667;
    font-family: 'source-sans-pro-regular';
}

.description-text {
    font-weight: 400;
    font-size: 16;
    color: #766667;
    font-family: 'source-sans-pro-regular';
}

.memory-name-text {
    font-weight: 600;
    font-size: 16;
    font-family: 'source-sans-pro-bold';
    color: '#766667'
}

.success-text{
    font-family: 'playfair-display';
    font-size: 18px;
    font-weight: 600;
    color: #503F40;
}

.success-explanation-text{
    font-family: 'source-sans-pro-regular';
    font-size: 16px;
    font-weight: 400;
    color: #503F40;
}
